import { SOCIAL_LIST } from 'src/constants/layout';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import styled from 'styled-components';

const WidgetFollow = ({ eventDataLayer }: any) => {
  return (
    <>
      <WidgetWrapper id='widget-follow'>
        {SOCIAL_LIST.map((item: any, index: any) => (
          <a
            tabIndex={-1}
            target='_blank'
            key={index}
            href={item?.link}
            rel='noopener nopopopener nofollow'
            aria-label='social'
            title='social'
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer,
                data: {
                  title: item?.name,
                  heading: item?.name,
                  index: index + 1
                }
              })
            }
          >
            <div className={`bg-item --${item.name || 'default'}`}>{item.icon}</div>
          </a>
        ))}
      </WidgetWrapper>
    </>
  );
};

const WidgetWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-left: 15px;
  }
  a:first-child {
    margin-left: 0px;
  }
  .bg-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    &.--default {
      background-color: rgba(0, 0, 0);
      border: 1px solid #fff;
    }
    &.--facebook {
      background-color: rgba(59, 89, 152);
      border: 1px solid #fff;
    }
    &.--X {
      background-color: rgb(0, 0, 0);
      border: 1px solid #fff;
    }
    &.--instagram {
      background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
        radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
      border: 1px solid #fff;
    }
    &.--youtube {
      background-color: rgba(255, 0, 0);
      border: 1px solid #fff;
    }
    @media (max-width: 1024px) {
      width: 42px;
      height: 42px;
    }
    svg {
      color: rgba(255, 255, 255, 1);
    }
    &:hover {
      background-color: #ed1a3b;
      svg {
        color: rgba(255, 255, 255);
      }
    }
  }
`;
export default WidgetFollow;
